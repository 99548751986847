body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 5000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.formCenter {
  text-align: center;
  margin-top: 100px;
}

.appBar {
  text-align: right !important;
  padding-left: 70%;
  color: var(--secondary);

}

.dialogtitle {
  font-size: 2rem !important;
  font-weight: 700 !important;
  background-color: var(--clr);
  color: var(--secondary)
}

.color {
  /* color: rgb(230, 230, 236); */
  background-color: rgb(211, 191, 161);
}

.logoimage {
  width: 40px;
  /* height: 5px; */
  padding-right: 10px;
}

.imglogin {
  width: 9vw !important;
  height: 9vh !important;

}

.paper-image {
  margin-top: 8cap !important;

}

.login-image {
  /* margin-top: 14cap!important; */
  width: 50vw;
  height: 70vh;
}

.video-Button {
  color: white !important;
  background-color: #9155fd !important;
  border: 1px solid #9155fd !important;
  width: 95%;
  text-align: center !important;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 8px;
  font-size: 1rem;
  border-radius: 5px;
  margin-bottom: 20px;
  line-height: 1.20rem;
  margin-top: 10px;
  margin-left: 28px;
  margin-right: 28px;
  padding-left: 10px;
  padding-right: 25px;
}

/* .Button { */
/* background-color: var(--clr); */
/* border-radius: 10px !important;
  color: var(--primary);
  min-width: 10vw !important;
  font-size: 16px !important;
  text-decoration: none!important;
    font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"!important;
    font-size: 0.9375rem!important;
    text-transform: uppercase!important;
 
} */
/* 
.Button:hover {
 
       text-decoration: none; 
     background-color: rgb(128, 75, 223)!important;
     box-shadow: rgba(58, 53, 65, 0.56) 0px 6px 18px -8px!important; 

  
} */

.Button2 {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
  text-align: center !important;
  justify-content: center;
  align-items: center;
}

/* .Reset {
  box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 8px -4px !important;
  background-color: rgba(145, 85, 253, 0.12)!important; */
/* color: rgb(145, 85, 253)!important; */
/* color:#9155fd!important;
  
  margin-right: 20px !important;
  border-radius: 10px !important;
  min-width: 10vw !important;
  font-size: 16px !important;
  text-decoration: none!important;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"!important;
  font-size: 0.9375rem!important;
  text-transform: uppercase!important;

} */

.Reset:hover {
  background-color: #c18eda !important;
}

.Textfield {
  /* margin-top: 10px; */
  margin-left: 20px;
  margin-right: 20px;
  /* padding-left: 10px;
  padding-right: 10px; */
  text-align: center;
  margin-bottom: 10px;
  justify-content: center;
}

.Text {
  background-color: var(--primary);
  color: var(--secondary);
  width: 100%;
  text-align: center;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
}

.Card {
  background: linear-gradient(to top,
      White 0%,
      White 50%,
      rgb(106, 183, 213) 50%,
      rgb(106, 183, 213) 100%);
}

.heading {
  width: 100%;
  padding: 5px;
  color: var(--secondaryheader);
  align-items: center;
  background: var(--primaryheader);
  margin-bottom: 10px;
}

.Margin {
  padding-left: 40%;
  text-align: right !important;
}


.pagination {
  margin-top: 10px;

}

.TableCell {
  background-color: rgb(13, 13, 13);
  color: var(--secondary);
}

.Dialog {
  height: 750px;
}


.count {
  column-count: 2;

}


.Bold {
  font-weight: bold;
  font-size: 16px;

}

.OrderButton {
  background-color: var(--clr);
  color: var(--secondary);
  width: 100%;
  margin-right: 40px;
  height: 45px;
  border-radius: 5px;
}

.Row {
  /* display: -moz-box; */
  display: flex;
  flex-wrap: wrap;
  /* margin-right: -15px;
  margin-left: -15px */

}

.Input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;

}

.Label {
  text-align: left;
  margin-left: 30px;
  margin-right: 30px;
}

.Label1 {
  text-align: left;
  margin-left: 30px;
  margin-right: 25px;
  width: 45%;
}

.loginBox {
  /* background-image: InCircleLogo.png; */
  background-image: url('./images/InCircleLogo.png');
  height: 80ch;
  width: 172ch;
  /* margin-top: 70px; */
  /* font-size: 50px; */
  background-size: cover;

  /* background-repeat: no-repeat; */

}

.loginform {
  margin: 1;
  width: 25vw;
  background-Color: var(--secondary);
}

.loginpaper {
  width: 30vw !important;
  height: 40vh !important;
  background-Color: red !important;
  border-Radius: 5px !important;
  align-items: center !important;
  /* margin:10!important; */

}

.Loader {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  color: var(--clr);
  margin-top: 15%;
}

.DashboardCard {
  margin-top: 30px;
  margin-left: 50px;
  margin-bottom: 30px;
}



.Dashboard {
  display: flex;
  direction: row;

}

.FontSize {
  font-size: 30px;
}

.DashboardBox {

  height: 25vh;
  width: 25ch;
  margin-top: 30px;
  border-radius: 10px;
  text-align: Center;
  padding-top: 30px;
  color: var(--secondary);
  cursor: pointer
}

.datetext {
  width: 14vb;
  height: 2vh !important;
  text-align: center;
}

.login {
  width: 22vw;
  height: 6vh;
  align-content: centre;
  background-color: var(--clr);
  text-decoration: none;
  text-decoration: none !important;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-size: 0.9375rem !important;
  text-transform: uppercase !important;
  box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 8px -4px;
}

.login:hover {
  text-decoration: none;
  background-color: rgb(128, 75, 223);
  box-shadow: rgba(58, 53, 65, 0.56) 0px 6px 18px -8px;
}

.TablePadding {
  padding-left: 5px;
  padding-right: 5px;
  /* min-height: 70vh !important; */
  margin-top: 20px;

}

.SearchButton {
  text-align: center;
  justify-content: center;
}


.textContainer {
  /* display: block; */
  /* width: 200px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.Switch {
  border: 1px solid !important;
  padding: 2px !important;
  /* border-radius: 5px!important; */
  width: 18% !important;
  height: 10% !important;
}

:root {
  --clr: black;
  --primary: #233044;
  --secondary: #FFFFFF;
  --secondaryheader: rgb(238, 238, 238) !important;
  --primaryheader: rgb(35, 48, 68) !important;
}

.Pagination1 {
  position: absolute;
}

.sideMenu {
  background-color: var(--primary) !important;
  color: #301934 !important;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  /* -webkit-text-decoration: none; */
  text-decoration: none;
  background-color: red;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover {
  /* -webkit-text-decoration: none; */
  text-decoration: none;
  background-color: black;
}

.Loading {
  margin-top: 13%;
  margin-bottom: 15%;
}



.SideBar :hover {
  background-color: rgb(32, 45, 65);
  color: white;
}

.menu {
  height: 10vh !important;
  width: 2vw !important;
  /* background-color: aqua !important; */
}

.tablehead {
  font-size: 17px !important;
  font-style: bold !important;
  background-color: var(--primaryheader) !important;
  color: var(--secondaryheader) !important;
}

.cell:hover {
  background-color: #BAB2B5 !important;
}

.DasboardBox {
  border-radius: 6px;
  /* padding : 20px 20px 20px 20px; */
  box-shadow: 1px 1px 4px 4px #C0C0C0;
  margin-left: 20px;
  margin-right: 15px;
  padding-left: 50px;
  padding-top: 5%;
  padding-bottom: 10%;
  padding-right: 50px;
}


.reset-button {
  box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 8px -4px !important;
  background-color: rgba(145, 85, 253, 0.12) !important;
  /* color: rgb(145, 85, 253)!important; */
  color: #9155fd !important;

  /* margin-right: 20px !important; */
  border-radius: 10px !important;
  min-width: 10vw !important;
  min-height: 0.5% !important;
  font-size: 16px !important;
  text-decoration: none !important;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-size: 0.9375rem !important;
  text-transform: uppercase !important;
}

.reset-button:hover {
  color: white !important;
  background-color: #9155fd !important;
}

.search-button {
  box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 8px -4px !important;
  background-color: #9155fd !important;
  /* color: rgb(145, 85, 253)!important; */
  color: white !important;

  /* margin-right: 20px !important; */
  border-radius: 10px !important;
  min-width: 10vw !important;
  font-size: 16px !important;
  text-decoration: none !important;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-size: 0.9375rem !important;
  text-transform: uppercase !important;
}

.search-button:hover {
  color: #9155fd !important;
  background-color: rgba(145, 85, 253, 0.12) !important;
}
.hove{
  color: aliceblue !important;
  }
  .hove:hover{
    background-color: #E9DDFE!important;
  
  }

.container{
  font-size: 25px!important;
 /* padding-left: 8px!important; */
 margin-Top: 10px!important;
}
   
    