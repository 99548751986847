.App {
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tableDataRow > .MuiTableCell-root{
padding: 0px !important;
}

.tableDataRow > .MuiTableCell-root > div {
  /* display: block; */
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 4px 2px 4px;
}
.contact{
  width:12vw!important;
}
.co{
  width:20px!important;
}
.com{
  width:50px!important;
}

.date > .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  height:10rem !important;
}
.tableRow > .MuiTableCell-root{
  padding: 0px !important;
}
/* .MuiPagination-root  {
  button  {
    color: red !important;
 }
} */
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root{
  /* -webkit-text-decoration: none; */
  text-decoration: none;
  background-color: red;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover {
  /* -webkit-text-decoration: none; */
  text-decoration: none;
  background-color: black;
}